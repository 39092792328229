import * as Sentry from '@sentry/nextjs';
import { isCancel } from 'axios';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import { publicPaths } from '~/hooks/useAuth';

import { CancelRouteChangeThrowable } from '~/hooks/useConfirmNavigation';
import { isAxiosErrorExactly } from '~/shared/utils';

const isIgnoreError = (error?: Error) => {
  if (process.env.ENV !== 'local') return false;
  if (error == null) return false;
  if (error.stack != null && error.stack.indexOf('hammer.js') >= 0) return true;
  if (
    error.message ===
      'ResizeObserver loop completed with undelivered notifications.' ||
    error.message === 'ResizeObserver loop limit exceeded'
  ) {
    // NOTE: 特定の環境で発生する無害なエラーのため、無視する
    return true;
  }
  return false;
};

const stopErrorEvent = (errorEvent: Event) => {
  errorEvent.stopImmediatePropagation();
  errorEvent.preventDefault();
};

const ThrowableFallback = ({ error }: { error: Error }) => {
  if (isIgnoreError(error)) return <></>;
  // NOTE: nextjs のカスタムエラーページにハンドリングするために、throwする
  throw error;
};

const getShouldRedirectToSigninPage = (error: Error) => {
  const is401 = isAxiosErrorExactly(error) && error.response?.status === 401;
  return is401 || isCancel(error);
};

const clearTokenAndRedirectToSignin = () => {
  window.localStorage.removeItem('jwt');
  // 無限リダイレクト防止
  if (!publicPaths.includes(window.location.pathname))
    window.location.href = '/signin'; // TODO: 値の初期化をケアした上でSPA的な遷移にする
};

const _ErrorBoundary = ({ children }: { children: ReactElement }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error, setError] = useState<null | Error>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const unhandledrejectionHandler = (rejection: PromiseRejectionEvent) => {
      if (rejection.reason instanceof CancelRouteChangeThrowable) return;
      if (getShouldRedirectToSigninPage(rejection.reason))
        return clearTokenAndRedirectToSignin();
      if (isIgnoreError(rejection?.reason)) return stopErrorEvent(rejection);
      setError(rejection.reason);
    };
    const errorHandler = (err: ErrorEvent) => {
      if (err.error != null && getShouldRedirectToSigninPage(err.error))
        return clearTokenAndRedirectToSignin();
      if (isIgnoreError(err?.error)) return stopErrorEvent(err);
      setError(err.error);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('unhandledrejection', unhandledrejectionHandler);
      window.addEventListener('error', errorHandler);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener(
          'unhandledrejection',
          unhandledrejectionHandler
        );
        window.removeEventListener('error', errorHandler);
      }
    };
  }, []);

  if (error) throw error;

  return children;
};

export const ErrorBoundary = Sentry.withErrorBoundary(_ErrorBoundary, {
  fallback: ThrowableFallback,
});
